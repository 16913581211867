import { Stripe, StripeElements, StripeError } from '@stripe/stripe-js';
import { FormEvent, useState } from 'react';
import { selectors, useAppSelector } from 'store';

import { actions } from '../../store/ducks/payments';
import { useAsyncAction } from '../index';

interface CustomFormEvent<T = Element> extends FormEvent<T> {
  elementType?: string;
}

const returnUrl = new URL('https://traineracademy.org/'); // TODO: вынести в константы

const useStripeUnauthorizedForm = (clientSecret: string | undefined, courseId: string) => {
  const [validateToken] = useAsyncAction(actions.validateToken);

  const [elements, setElements] = useState<StripeElements | null>(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAccountExists, setIsAccountExists] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSucceeded, setIsSucceeded] = useState(false);

  const email = useAppSelector(selectors.auth.selectEmail);
  const disabled = !stripe;

  const confirmPayment = async (stripe: Stripe, elements: StripeElements) => {
    try {
      if (!clientSecret) {
        return;
      }
      setIsLoading(true);

      const { confirmationToken } = await stripe.createConfirmationToken({
        elements,
        params: {
          return_url: returnUrl.href,
          payment_method_data: {
            billing_details: { email },
          },
        },
      });

      if (confirmationToken) {
        const { isAccountExists } = await validateToken({
          courseId,
          confirmationToken: confirmationToken.id,
          useToken: false,
        });

        if (isAccountExists) {
          setIsAccountExists(true);
          return;
        }

        if (!isAccountExists) {
          setIsAccountExists(false);
        }
      }

      const responses =
        (await stripe.confirmPayment({
          clientSecret,
          // @ts-ignore
          redirect: 'if_required',
          confirmParams: {
            // @ts-ignore
            confirmation_token: confirmationToken?.id,
            payment_method_data: {
              billing_details: {
                email: email.toLowerCase(),
              },
            },
          },
        })) || {};

      if (responses?.error?.setup_intent?.last_setup_error?.message || responses?.error) {
        setError(responses?.error);
        setCashAppError(responses?.error.setup_intent?.last_setup_error?.message);
      } else {
        setIsSucceeded(true);
      }
    } catch (error: any) {
      setErrorMessage('Something went wrong');
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setError = (error?: StripeError) => {
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setErrorMessage(error?.message || '');
    } else if (error) {
      setErrorMessage('An unexpected error occurred.');
    }
    setIsLoading(false);
  };

  const setCashAppError = (error?: string) => {
    setErrorMessage(error || 'An unexpected error occurred.');
    setIsLoading(false);
  };

  const handleSubmit = async (e: CustomFormEvent<HTMLFormElement>) => {
    if (e?.elementType !== 'expressCheckout') {
      e?.preventDefault();
    }

    if (!stripe || !elements) {
      return;
    }

    const submitResponses = await elements.submit();

    if (!submitResponses.error) {
      await confirmPayment(stripe, elements);
    }
  };

  return {
    handleSubmit,
    errorMessage,
    isLoading,
    isAccountExists,
    disabled,
    isSucceeded,
    setStripe,
    setElements,
    stripe,
    elements,
  };
};

export default useStripeUnauthorizedForm;
