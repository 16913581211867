import { Stripe, StripeElements } from '@stripe/stripe-js';
import { PaymentMethodDto, StripePaymentSource, SubscribeDtoPeriodEnum, SubscriptionLevel } from 'api/generated';
import { AppConfig } from 'config';
import { routes } from 'constant/routes';
import { useGoogleAnalytics } from 'hooks';
import { FormEvent, useState } from 'react';
import { setErrorStripe } from 'utils';

interface FormProps {
  elements: StripeElements | null;
  stripe: Stripe | null;
  clientSecret: string | undefined;
  courseId?: string;
  promocode: string | '';
  level?: SubscriptionLevel;
  source?: StripePaymentSource;
  period?: SubscribeDtoPeriodEnum;
  amount?: number;
  courseName?: string;
  isTrial?: boolean;
  isPurchase?: boolean;
  setTransactionId: any;
  isActivate: boolean | undefined;
}

interface CustomFormEvent<T = Element> extends FormEvent<T> {
  elementType?: string;
}

interface ConfirmStripeArgs {
  stripe: Stripe;
  clientSecret: string;
  elements?: StripeElements;
  paymentMethodId?: string;
}

export const useStripeSubscribeOrPurchase = ({
  elements,
  stripe,
  clientSecret,
  courseId,
  promocode,
  level,
  source,
  period,
  amount,
  courseName,
  isTrial,
  isPurchase,
  setTransactionId,
  isActivate,
}: FormProps) => {
  const {
    getEventSubscriptionPayPressedGA,
    getEventSubscribeGA,
    getEventPurchaseGA,
    getEventSubscriptionFromTrialVersionGA,
    getEventPurchaseFromTrialVersionGA,
  } = useGoogleAnalytics();

  const [isSuccess, setIsSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const returnUrl = new URL(routes.addNewCourse, AppConfig.BASE_API_URL || '');

  const handleSubmit = async (e?: CustomFormEvent<HTMLFormElement>, paymentMethod?: PaymentMethodDto) => {
    if (e?.elementType !== 'expressCheckout') {
      e?.preventDefault();
    }

    setIsLoading(true);
    getEventSubscriptionPayPressedGA();

    if (!stripe || !courseId || !level || !source || !period) return;

    try {
      if (paymentMethod && clientSecret) {
        await confirmStripe({
          stripe,
          elements: undefined,
          clientSecret,
          paymentMethodId: paymentMethod.id,
        });
      }

      if (!paymentMethod && elements) {
        await confirmStripe({ stripe, elements, clientSecret: '', paymentMethodId: undefined });
      }

      setIsLoading(false);
    } catch (error: any) {
      console.log('Error =>  ', error);
      setErrorStripe({
        error,
        setErrorMessage,
      });

      setIsSuccess(false);
      setIsLoading(false);
    }
  };

  const confirmStripe = async ({ stripe, elements, clientSecret, paymentMethodId }: ConfirmStripeArgs) => {
    const params = {
      elements,
      redirect: 'if_required' as const,
      clientSecret: clientSecret,
      confirmParams: {
        payment_method: paymentMethodId,
        return_url: returnUrl.href,
        mandate_data: paymentMethodId && {},
      },
    };

    try {
      const paymentIntent = (await stripe.retrievePaymentIntent(clientSecret)).paymentIntent;
      setTransactionId(paymentIntent?.id);
    } catch (error) {
      console.log(error);
    }

    try {
      elements && (await elements.submit());

      const res = await stripe.confirmPayment(params).then((res) => {
        return res;
      });

      if (!res.error) {
        setIsSuccess(true);
      }

      if (isTrial && courseId && amount && level && period) {
        const eventParams = {
          amount,
          courseId,
          subscribeType: level,
          courseName,
          promoCode: promocode,
          term: period,
        };

        const eventFunction = isPurchase ? getEventPurchaseGA : getEventSubscribeGA;

        if (isPurchase && isActivate) {
          getEventPurchaseFromTrialVersionGA();
          eventFunction(eventParams);
        }

        if (!isPurchase && isActivate) {
          getEventSubscriptionFromTrialVersionGA();
          eventFunction(eventParams);
        }
      }
    } catch (error: any) {
      setErrorMessage('Something went wrong');
    }
  };
  return {
    handleSubmit,
    isLoading,
    errorMessage,
    isSuccess,
  };
};
