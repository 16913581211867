import { Stripe, StripeElements } from '@stripe/stripe-js';
import api from 'api';
import { StripePaymentSource, SubscribeDtoPeriodEnum, SubscribeDtoTypeEnum, SubscriptionLevel } from 'api/generated';
import { useAsyncAction, useStripeChangePlan, useStripeSubscribeOrPurchase } from 'hooks';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { actions } from 'store';

interface Props {
  stripe: Stripe | null;
  elements: StripeElements | null;
  courseId?: string;
  promocode: string;
  period?: SubscribeDtoPeriodEnum;
  level?: SubscriptionLevel;
  source?: StripePaymentSource;
  isTrial?: boolean;
  isUpdate?: boolean;
  isPurchase?: boolean;
  courseName?: string;
  amount?: number;
  subscribeType: SubscribeDtoTypeEnum;
  setInvoiceId: Dispatch<SetStateAction<string>>;
  clientSecret: string | undefined;
  setClientSecret: Dispatch<SetStateAction<string | undefined>>;
  setTransactionId: Dispatch<SetStateAction<string | undefined>>;
  isActivate: boolean | undefined;
}

export const useStripeDefault = ({
  stripe,
  elements,
  courseId,
  promocode,
  period,
  level,
  source,
  isTrial,
  isUpdate,
  isPurchase,
  courseName,
  amount,
  subscribeType,
  clientSecret,
  setInvoiceId,
  setClientSecret,
  setTransactionId,
  isActivate,
}: Props) => {
  // @ts-ignore
  const [subscribeCourseAction] = useAsyncAction(actions.payments.subscribeCourse);
  // @ts-ignore
  const [purchaseCourseAction] = useAsyncAction(actions.payments.purchaseCourse);

  const params = {
    elements,
    stripe,
    clientSecret,
    courseId,
    source,
    period,
  };

  const {
    handleSubmit: handleSubmitSubscribeOrPurchase,
    isLoading: isLoadingSubscribeOrPurchase,
    errorMessage: errorMessageSubscribeOrPurchase,
    isSuccess: isSuccessSubscribeOrPurchase,
  } = useStripeSubscribeOrPurchase({
    ...params,
    promocode,
    level,
    isTrial,
    isPurchase,
    courseName,
    amount,
    setTransactionId,
    isActivate,
  });

  const {
    handleSubmit: handleSubmitChangePlan,
    isLoading: isLoadingChangePlan,
    errorMessage: errorMessageChangePlan,
    isSuccess: isSuccessChangePlan,
  } = useStripeChangePlan({
    ...params,
    level: subscribeType,
    setTransactionId,
  });

  const onGetClientSecretSubscribeOrPurchase = async () => {
    if (!courseId || !level || !source || !period) return;

    const purchaseParams = {
      courseId,
      promocode,
      source,
    };

    const subscribeParams = {
      courseId,
      promocode,
      level,
      source,
      period,
    };

    console.log(subscribeParams, 'params');

    const { clientSecret, invoiceId } = isPurchase
      ? await purchaseCourseAction(purchaseParams)
      : await subscribeCourseAction(subscribeParams);

    setClientSecret(clientSecret);
    setInvoiceId(invoiceId);
  };

  const onGetClientSecretChangePlan = async () => {
    const { data } = await api.Stripe.stripeControllerCustomerSetupIntentSecret();

    setClientSecret(data.clientSecret || '');
  };

  useEffect(() => {
    if (isUpdate && !isPurchase) {
      onGetClientSecretChangePlan();
    }

    if (stripe && (!isUpdate || isPurchase)) {
      onGetClientSecretSubscribeOrPurchase();
    }
  }, [stripe]);

  const isDisabled = !stripe || !clientSecret;

  return {
    clientSecretStripe: clientSecret,
    handleSubmitStripe: isUpdate && !isPurchase ? handleSubmitChangePlan : handleSubmitSubscribeOrPurchase,
    errorMessageStripe: isUpdate && !isPurchase ? errorMessageChangePlan : errorMessageSubscribeOrPurchase,
    isLoadingStripe: isUpdate && !isPurchase ? isLoadingChangePlan : isLoadingSubscribeOrPurchase,
    isSuccessStripe: isUpdate && !isPurchase ? isSuccessChangePlan : isSuccessSubscribeOrPurchase,
    isDisabledStripe: isDisabled,
  };
};
